@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap");

/* Tailwindcss directives */

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Ends here */
html,
body,
#root,
:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #3498db;
  --toastify-color-success: #4338ca;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #e74c3c;
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);

  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);

  --toastify-toast-width: 320px;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: sans-serif;
  --toastify-z-index: 9999;

  --toastify-text-color-light: #757575;
  --toastify-text-color-dark: #fff;

  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #4338ca;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;

  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;

  --toastify-color-progress-light: linear-gradient(to right,
      #4cd964,
      #5ac8fa,
      #007aff,
      #34aadc,
      #5856d6,
      #ff2d55);

  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
}

.app,
.content {
  height: 100%;
  width: 100%;
  font-family: "Poppins", sans-serif;
}

.c1 {
  position: absolute;
  width: 851px;
  height: 850px;
  left: -245px;
  top: -75px;

  background: #ff6633;
  opacity: 0.1;
  filter: blur(157px);
  z-index: -1;
}

.c2 {
  position: absolute;
  width: 850px;
  height: 850px;
  left: 147px;
  top: -346px;

  background: #22263a;
  opacity: 0.1;
  filter: blur(157px);
  z-index: -1;
}

.c3 {
  position: absolute;
  width: 106px;
  height: 106px;
  left: 553px;
  top: 162px;

  background: #ff6633;
  opacity: 0.1;
  filter: blur(157px);
  z-index: -1;
}

.c4 {
  position: absolute;
  width: 851px;
  height: 85px;
  left: -43px;
  top: 363px;

  background: #5843bd;
  opacity: 0.1;
  filter: blur(157px);
  z-index: -1;
}

.app {
  display: flex;
  position: relative;
}

.accent-red {
  color: #F05252;
}

.accent-purple {
  color: #5843BD
}

body::-webkit-scrollbar {
  width: 0px;
}

.ScrollOnly::-webkit-scrollbar {
  width: 10px
}

.NoScroll::-webkit-scrollbar {
  width: 0px;
}

/* Narrow scrollbar width for screens less than 620px */
@media (max-width: 768px) {

  /* Reduce the scrollbar width to 1px or any desired width */
  ::-webkit-scrollbar {
    width: 1px;
  }

  .ScrollOnly::-webkit-scrollbar {
    width: 1px
  }
}

/* Narrow scrollbar width for screens less than 620px */
@media (min-width: 768px) and (max-width: 1024px) {

  /* Reduce the scrollbar width to 1px or any desired width */
  ::-webkit-scrollbar {
    width: 5px;
  }

  .ScrollOnly::-webkit-scrollbar {
    width: 5px
  }

  * {
    box-sizing: border-box;
  }
}


/* Narrow scrollbar width for screens less than 620px */
@media (min-width: 1024px) {

  /* Reduce the scrollbar width to 1px or any desired width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  .ScrollOnly::-webkit-scrollbar {
    width: 10px
  }
}

.transform-180 {
  transform: rotate(180deg);
}

.tabarea::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.ChatMessage::-webkit-scrollbar,
.MessageScroll::-webkit-scrollbar,
.Landing::-webkit-scrollbar {
  width: 5px;
}

.payment::-webkit-scrollbar {
  width: 0px
}

.chatInboxbox {
  border-radius: var(--8, 8px);
  background: #FFF;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
}

.inputborder {
  border-top-left-radius: var(--8, 8px);
  border-bottom-left-radius: var(--8, 8px);
  max-height: 180px;
}

@layer components {
  .hr {
    @apply h-[2px] w-[40px] rounded-full bg-pry-600;
  }

  .details-nav {
    @apply text-sm text-pry-600 px-4 py-1;
  }

  .details-nav.active {
    @apply bg-pry-900 rounded-full text-white;
  }

  .label-span {
    @apply flex items-center text-sm font-semibold text-slate-700 before:content-[''] before:w-1 before:h-1 before:rounded-full before:inline-block before:bg-pry-200 before:mr-4;
  }

  .hr-span {
    @apply flex items-center w-full h-[2px] my-2 bg-pry-600 rounded-full relative before:content-[''] before:absolute before:w-16 before:h-[4px] before:bg-pry-800 before:rounded before:left-6;
  }

  .input-fields {
    @apply mt-1 block px-3 py-2 bg-white border border-slate-300 rounded-lg text-sm shadow-sm placeholder-slate-400 placeholder:pl-4 focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500 invalid:border-pink-500 invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500;
  }
}

input.num {
  appearance: none;
}

.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.btn-gradient {
  background: linear-gradient(142.45deg, #ff6633 -15.52%, #5843bd 106.4%);
}

.bg-gradient {
  background: linear-gradient(265.85deg, #5843BD 32.61%, #FF6633 124.02%);
}

.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.review-gradient {
  background: linear-gradient(to left top,
      rgba(88, 67, 189, 0.3),
      rgba(88, 67, 189, 0),
      rgba(250, 250, 250, 0.7));
}

.review-gradient {
  background: linear-gradient(to left,
      rgba(88, 67, 189, 0.3),
      rgba(88, 67, 189, 0),
      rgba(217, 217, 217, 0.2));
}

.btn__gradient {
  background: linear-gradient(142.45deg,
      rgb(255, 102, 51) -15.52%,
      rgb(88, 67, 189) 106.4%);
}

.wrapper__gradient {
  background: linear-gradient(142.45deg,
      rgba(255, 102, 51, 0.2) -15.52%,
      rgba(88, 67, 189, 0.15) 106.4%);
}

@media only screen and (max-width: 1023px) {
  .nav__wrapper {
    padding: 2rem 0;
    background-color: white;
    border-radius: 24px;
    margin-left: 100vw;
    transition: all 500ms ease;
    /* pointer-events: none; */
    z-index: 20;
  }

  .nav__wrapper__active {
    margin-left: 0;
  }

  .nav-mobile {
    z-index: 10;
    position: fixed;
    height: 100vh;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgba(10, 10, 10, 0.6);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 100vw;
    backdrop-filter: blur(4px);
    transition: all 500ms ease;
  }

  .nav_item-mobile {
    margin-inline: auto;
    width: 300px;
    text-align: center;
  }

  .nav-mobile__active {
    margin-left: 0;
  }

  .nav_item-mobile>li {
    transition: all 200ms ease-in;
  }

  .nav_item-mobile>li:hover {
    color: #ff6633;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none;
}

.custom-file-upload {
  display: flex;
  width: max-content;
  max-width: 250px;
  align-items: center;
  cursor: pointer;
  padding: 12px 0px;
  font-weight: 600;
  font-size: 16px;
  background-color: transparent;
  color: #f24e1e;
  border: none;
  border-radius: 5px;
  white-space: nowrap;
}

.backdrop {
  backdrop-filter: blur(4px);
  height: max-content;
}

.wrap {
  overflow-wrap: break-word;
}

.bg-blue-700 {
  border-radius: 50%;
}

.accent-red {
  color: #F05252;
}

.MuiFormControl-root {
  width: 100%;
}

.word-spacing-2 {
  word-spacing: 2px;
}

.ellipsis {
  text-overflow: ellipsis;
}

.job_shadow {
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.10);
}

/* google translate button style */
.translateBTN {
  background: linear-gradient(to right, hsl(336, 34%, 50%), hsl(291, 31%, 44%), hsl(252, 47%, 50%));
  color: white;
  font-weight: 600;
  font-family: "Poppins";
}

.option:hover {
  background-color: hsl(336, 34%, 50%);
  color: white;
  ;
}

.VIpgJd-ZVi9od-ORHb-OEVmcd {
  display: none;
}

.VIpgJd-yAWNEb-L7lbkb div, .VIpgJd-yAWNEb-L7lbkb, .VIpgJd-ZVi9od-aZ2wEe-wOHMyf-ti6hGc {
  display: none;
}

div#goog-gt-tt.VIpgJd-suEOdc.VIpgJd-yAWNEb-L7lbkb.skiptranslate {
  background: none;
  display: none;
  border: none;
}

font.VIpgJd-yAWNEb-VIpgJd-fmcmS-sn54Q  {
  background: none;
  box-shadow: none;
}

/* .ulClass {
  list-style-type: square
} */

.upload {
  box-shadow: 0px 0px 20px -7px rgba(0,0,0,0.75);
  -webkit-box-shadow: 0px 0px 20px -7px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 20px -7px rgba(0,0,0,0.75);
}

@media (min-width: 1826px) {
  .show-tbtn {
    display: none;
  }

  .nav-right {
    margin-right: 0px;
  }
}

@media (min-width: 1440px) and (max-width: 1826px) {
  .show-tbtn {
    display: none;
  }

  .nav-right {
    margin-right: 0px;
  }
}

@media(min-width: 1200px) and (max-width: 1440px) {
  .show-tbtn {
    display: none;
  }

  .nav-right {
    margin-right: 0px;
  }
}

@media (min-width: 1031px) and (max-width: 1200px) {
  .show-tbtn {
    display: none;
  }

  .nav-right {
    margin-right: 0px;
  }
}

@media (min-width: 767px) and (max-width: 1030px) {
  .show-tbtn {
    display: none;
  }

  .nav-right {
    margin-right: 0px;
  }
}

@media (min-width: 490px) and (max-width: 766px) {
  .show-tbtn {
    display: block;
  }

  .translateBTN,
  select:not([size]):not(#openingtime):not(#closingtime) {
    background: linear-gradient(to right, hsl(336, 34%, 50%), hsl(291, 31%, 44%), hsl(252, 47%, 50%));
    color: white;
    font-weight: 600;
    font-family: "Poppins";
    width: 100vw;
    display: flex;
    justify-content: center;
  }

  .option {
    text-align: center;
  }

  * {
    box-sizing: border-box;
  }

  .nav-right {
    margin-right: 0px;
  }

  .VIpgJd-yAWNEb-VIpgJd-fmcmS-sn54Q  {
    background-color: transparent;
    box-shadow: none;
  }
}

@media(max-width: 489px) {
  .show-tbtn {
    display: block;
  }

  .translateBTN,
  select:not([size]):not(#openingtime):not(#closingtime) {
    background: linear-gradient(to right, hsl(336, 34%, 50%), hsl(291, 31%, 44%), hsl(252, 47%, 50%));
    color: white;
    font-weight: 600;
    font-family: "Poppins";
    display: flex;
    justify-content: center;
  }

  .option {
    text-align: center;
  }

  .nav-right {
    margin-right: 0;
  }
  .VIpgJd-yAWNEb-VIpgJd-fmcmS-sn54Q  {
    background-color: transparent;
    box-shadow: none;
  }
}

/* google translate button style end */

.gradient-text {
  background-clip: text;
  color: transparent;
  background-image: linear-gradient(266deg, #5843BD 32.61%, #F63 124.02%);
}

body {
  top: 0px !important;
}

.job-background {
  background: radial-gradient(closest-side at 60% 55%, #E2F0FB 20%, #E2F0FB 30%, #F6F7FD 80%, #F3F8FD 20%);
}

.moil_class {
  position: relative;
}

.moil_class::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(closest-side at 60% 55%, hsl(206, 76%, 94%, 0.7) 20%, hsl(206, 76%, 94%, 0.7) 30%, hsl(231, 64%, 98%, 0.7) 80%, hsl(210, 71%, 97%, 0.7) 20%), url("https://res.cloudinary.com/drlcisipo/image/upload/v1705704267/Website%20images/moil_zafedh.png") no-repeat center;
  transform: skewY(-10deg);
  z-index: -1;
}

.blob_1 {
  animation: move 10s ease-in-out infinite;
  transform-origin: 50% 50%;
  filter: blur(200px);
  max-width: none;
  position: absolute;
  top: 0%;
  bottom: auto;
  left: 0%;
  right: auto;
}

.blob_2 {
  animation: move_1 10s ease-in-out infinite;
  transform-origin: 50% 50%;
  filter: blur(200px);
  max-width: none;
  position: absolute;
  top: 0%;
  bottom: auto;
  left: 0%;
  right: auto;
}

@keyframes move {
  0% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(50vw, 50vh);
  }

  100% {
    transform: translate(100vw, 100vh);
  }
}

@keyframes move_1 {
  0% {
    transform: translate(100vw, 0);
  }

  50% {
    transform: translate(50vw, 50vh);
  }

  100% {
    transform: translate(0, 100vh);
  }
}


/* Define the keyframes for the gradient animation */
@keyframes movingGradient {
  0% {
    background-position: 0% 0%;
  }

  50% {
    background-position: 50% 50%;
  }

  100% {
    background-position: 0% 0%;
  }
}

/* Apply the gradient animation to an element */
.element2 {
  background: linear-gradient(266deg, #5843BD 32.61%, #F63 124.02%);
  background-size: 200% 100%;
  /* You can adjust the size for the desired effect */
  animation: movingGradient 5s linear infinite;
  /* Adjust the duration as needed */
}

.css-13cymwt-control,
.css-t3ipsp-control {
  height: 100%;
  border-color: rgb(129 140 248);
}

.submit-form-gradient {
  background: linear-gradient(to bottom right, hsl(312, 31%, 45%) 20%, hsl(252, 46%, 50%) 30%,hsl(252, 46%, 50%) 10%, hsl(306, 30%, 44%), hsl(348, 42%, 54%), hsl(348, 42%, 54%));
}

/* .submit-form-gradient {
  background-color: #ff6633;
  background-image: #FF6633;
  background-image: -moz-linear-gradient(356.01% 1368.88% at 36.89% 50%, #5843BD 0%, #FF6633 100%); 
  background-image: -webkit-radial-gradient(356.01% 1368.88% at 36.89% 50%, #5843BD 0%, #FF6633 100%); 
  background-image: radial-gradient(356.01% 1368.88% at 36.89% 50%, #5843BD 0%, #FF6633 100%); 
  background-size: 300%;
  background-repeat: no-repeat;
  background-position: 0%;
  -webkit-transition: background 1000ms ease-in-out;
}

.submit-form-gradient:hover {
  background-position: -200%;
  transition: background 1000ms ease-in-out;
} */

.gradient-radio {
  -webkit-appearance: none;
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  background: #f8f7f7;
  border: 3px solid #848383;
  cursor: pointer;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.gradient-radio::before {
  content: '';
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  pointer-events: none;
  background-image: url(https://res.cloudinary.com/drlcisipo/image/upload/v1709256321/Group_mfwrrg.svg);
  background-size: contain;
  background-repeat: no-repeat;
}

.gradient-radio::before {
  display: none;
}

.gradient-radio:checked::before {
  display: block;
}

.gradient-radio:checked {
  background: linear-gradient(142.45deg, #FF6633 -15.52%, #5843BD 106.4%);
}

.gradient-radio:checked::marker {
  background: white;
}



.custom-grid {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 340px 1fr;
}

@media screen and (min-width: 768px) {
  .custom-grid {
    display: grid;
    grid-template-columns: 1.3fr 0.7fr;
    grid-template-rows: auto;
  }
}

.resp-width {
  width: calc(100% - 48px);
}

.hire-cand-shadow {
  box-shadow: 0px 4px 25px 2px #00000012;
}

.resume_page {
  box-shadow: 0px 0px 25px 0px #0000000d;
}

.hire-cand-purple {
  background: #5843BD0D;
  border: 1.5px solid #5843BD;
}

.hire-cand-orange {
  border: 1.5px solid #FF6633
}

.pac-container::after {
  display: none;
}

.css-142iz10 {
  padding-left: 0px;
  padding-right: 0px;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  padding-inline: 16px;
  padding-block: 8px;
  width: 100%;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input::placeholder {
  color: #0000004D;
}

.MuiStack-root {
  width: 100%;
}

.profile-header-border {
  border: 4px solid #FFFFFF80;
  background: #F5F9FF;
  padding-inline: 14px;
  padding-block: 26px;
  border-radius: 50%;
}

.incomplete-profile-border {
  border: 4px solid #5843BD1A;
  background: #F5F9FF;
  padding: 1rem;
  border-radius: 50%;
}


.incomplete-profile-image {
  border: 4px solid #D7DBF7;
  background: #000;
  padding: 0.25rem;
  border-radius: 50%;
}

.profile-pics-upload {
  border: 1px dashed #FF6633;
  background: #FF66330D;
  border-radius: 0.5rem;
}
.CircularProgressbar-text {
  text-align: center;
  width: 100%;
}

.jobShadow {
  box-shadow: 0px 4px 16px 0px #0000001A;
}

.resp-width {
  width: calc(100% - 48px);
}


.ql-container.ql-snow {
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  height: 10.4rem;
  border: 1px solid #8da2fb;
}

.ql-toolbar {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  border: 1px solid #8da2fb;
}

.ql-toolbar:focus {
  border: 1px solid #8da2fb;
}

.ql-toolbar.ql-snow {
  padding-top: 20px;
  padding-bottom: 20px;
}

.sidebar-gradient {
  background: linear-gradient(90deg, rgba(88,67,189,1) 100%, rgba(17,17,18,1) 100%);
}